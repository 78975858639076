/* You can add global styles to this file, and also import other style files */
@import 'assets/less/variables.less';
@import 'assets/less/fonts.less';
@import 'assets/less/util.less';
@import 'assets/less/tooltip.less';
@import 'assets/less/modal.less';

html,
body {
  padding: 0px !important;
  margin: 0px !important;
  background: @body-bg  !important;
  font-family: Helvetica Neue;
  font-size: @default-font-size;
  height: 100%;
  box-sizing: border-box;

}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background: #EAEAEA;
  border: 9px solid #EAEAEA;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #A9A9A9;
  z-index: -1;
  border: 0px solid #EAEAEA;
}

.main-container {
  background: @white-bg;
  width: 100%;
  max-width: @container-width;
  .auto-margin();

  &.padding-bottom74 {
    padding-bottom: 74px;
  }

  .skywards-header {
    background: @white-bg;
    height: 310px;

    @media only screen and (max-width: 600px) {
      height: 210px;
    }

    .logo {
      float: left;
      width: 100%;

      img {
        padding: 0px 20px;
        float: left;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      .powered-by {
        color: @title-color;
        font-size: 14px;
        line-height: 12px;
        padding-left: 10px;
        float: left;
        padding-top: 35px;

        span {
          color: @title-color;
          font-size: 14px;
          padding-top: 2px;
          float: left;

          &.signin-title {
            color: @title-color-white  !important;
          }
        }

        img {
          float: left;
          padding: 0px 5px;
        }
      }

      .right-text-container {
        .right-text-container();
      }
    }

    .skywards-title {
      @media only screen and (max-width: 600px) {
        font-size: 27px;
        line-height: 27px;
        padding-top: 20px;
      }

      font-size: 50px;
      line-height: 50px;
      text-align: center;
      font-family: Emirates;
      padding-bottom: 10%;
      color: @title-color;
      float: left;
      width: 100%;
      font-weight: 600;

      .no-margin {
        margin: 0px !important;
      }
    }

    &.signin-header {
      background: url('/../assets/img/Base@2x.png') no-repeat !important;
    }
  }

}

.modal {
  &.payementsCustomModal {
    box-sizing: border-box;

    .modal-dialog {
      width: 1000px;
      height: 733px;
      max-width: 1000px;
      padding: 0px !important;
      margin: 5.75rem auto !important;

      @media only screen and (max-width: 1000px) {
        max-width: 900px;
        width: auto;
      }

      .modal-body {
        padding: 0px !important;
        width: 100%;

        .payment-header {
          height: 175px;
          width: 100%;
          background-color: rgba(246, 246, 246, 0.75);
          position: relative;

          img {
            height: 175px;
            width: 100%;
            opacity: 0.5;
            position: absolute;
          }

          .details {
            width: 100%;
            position: absolute;

            p {
              margin: 0px;
              height: 30px;
              color: @font-black;
              font-family: Emirates;
              font-size: 28px;
              font-weight: 500;
              line-height: 30px;
              padding-left: 60px;
              font-weight: 600;
            }

            .title {
              padding-top: 77px;
            }

            .account-details {
              height: 16px;
              color: @font-black;
              font-family: "Helvetica Neue";
              font-size: 14px;
              line-height: 16px;
              padding-top: 10px;
            }

            .account-number {
              height: 17px;
              color: @font-black;
              font-family: "Helvetica Neue";
              font-size: 14px;
              font-weight: 500;
              line-height: 17px;
              padding-top: 5px;
            }

            .acc-details {
              width: 50%;
              float: left;
            }

            button {
              float: right;
              margin-right: 60px;
              height: 40px;
              width: 160px;
              border-radius: 4px;
              background-color: #F00;
              color: #FFFFFF;
              font-family: "Helvetica Neue";
              font-size: 14px;
              font-weight: bold;
              line-height: 12px;
              text-align: center;
              outline: none;
              cursor: pointer;
              padding-top: 2px;
              border: #F00;
            }
          }
        }

        .payment-body {
          width: 100%;
          padding: 30px 50px 30px 50px;
          box-sizing: border-box;

          .title {
            color: #000000;
            font-family: Emirates;
            font-size: 28px;
            font-weight: 500;
            line-height: 30px;
            margin: 0px;
            margin-bottom: 18px;
            font-weight: 600;
          }

          .account-details {
            .label-container {
              padding-top: 10px;

              label {
                display: inline-block;
                width: 30%;
                color: #666666;
                font-size: 14px;
                line-height: 20px;
              }

              span {
                display: inline-block;
                width: 70%;
                color: #000000;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
              }
            }

          }

          .account-information {
            display: block;
            color: #000000;
            font-size: 14px;
            line-height: 16px;
            margin-top: 30px;
          }
        }
      }
    }
  }

  &.contentSuccessModal {
    box-sizing: border-box;

    .modal-dialog {
      height: 502px;
      width: 742px;
      padding: 0px !important;
      margin: 10.75rem auto !important;

      .modal-content {
        height: 100%;
        width: 100%;
        position: relative;

        .modal-body {
          padding: 0px !important;
          width: 100%;
          position: absolute;
          top: 0%;

          .content-body {
            width: 100%;
            padding: 30px 50px 30px 50px;
            box-sizing: border-box;

          }
        }
      }

    }
  }

  &.payementsCustomModalDownload{
    box-sizing: border-box;
    .modal-dialog{
      width:874px;
      height:580px;
      margin-top:178px;
    }
  }

  &.offerCommDatePicker{
    box-sizing: border-box;
    .modal-dialog{
      width:494px;
      margin-top:150px;
      border-radius: 4px;
      background-color: #F6F6F6;
    }
  }

  &.offerOverlapModal {
    box-sizing: border-box;

    .modal-dialog {
      height: 548px;
      width: 742px;
      padding: 0px !important;
      margin: 10.75rem auto !important;

      .modal-content {
        height: 100%;
        width: 100%;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;

        .modal-body {
          padding: 0px !important;
          width: 100%;

          .overlap-header {
            height: 338px;
            background-image: url(/../assets/img/message_background.png);
            background-repeat: no-repeat;
            background-position: center center;
            padding-top: 115px;
            box-sizing: border-box;
          }

          .content-body {
            width: 100%;
            padding: 30px 50px 30px 50px;
            box-sizing: border-box;

          }
        }
      }

    }
  }

  &.offerCreationModal {
    box-sizing: border-box;

    .modal-dialog {
      height: 489px;
      width: 742px;
      padding: 0px !important;
      margin: 10.75rem auto !important;
      border-radius: 2px;

      .modal-content {
        height: 100%;
        width: 100%;
        position: relative;

        .modal-body {
          padding: 0px !important;
          width: 100%;
          margin-top: 85px;
          position: absolute;
          top: 0%;
        }
      }

    }
  }

  &.pinResetModal,
  &.logoutModal {
    box-sizing: border-box;

    .modal-dialog {
      box-sizing: border-box;
      height: 307px;
      width: 743px;
      padding: 0px !important;
      margin: 10.75rem auto !important;

      .modal-content {
        height: 100%;
        width: 100%;

        .modal-body {
          padding: 0px !important;
          width: 100%;

          .content-body {
            width: 100%;
            padding: 30px 50px 30px 50px;
            box-sizing: border-box;
          }
        }
      }

    }
  }

  &.locationpayschemeserrorscont{
    box-sizing: border-box;

    .modal-dialog {
      box-sizing: border-box;
      height: 307px;
      width: 743px;
      padding: 0px !important;
      margin: 10.75rem auto !important;

      .modal-content {
        height: 100%;
        width: 100%;

        .modal-body {
          padding: 0px !important;
          width: 100%;

          .content-body {
            width: 100%;
            padding: 30px 50px 30px 50px;
            box-sizing: border-box;
          }
        }
      }

    }
  }

  &.refundModal {
    box-sizing: border-box;

    .modal-dialog {
      box-sizing: border-box;
      width: 609px;
      padding: 0px !important;
      margin: 10.75rem auto !important;

      .modal-content {
        height: 100%;
        width: 100%;

        .modal-body {
          padding: 0px !important;
          width: 100%;

          .content-body {
            width: 100%;
            padding: 30px 50px 30px 50px;
            box-sizing: border-box;
          }
        }
      }

    }
  }


  &.locationsModal {
    box-sizing: border-box;

    .modal-dialog {
      box-sizing: border-box;
      height: 860px;
      width: 1100px;
      padding: 0px !important;
      margin: 2.75rem auto !important;

      @media only screen and (min-width: 1450px) {
        width: 1200px;
      }

      .modal-content {
        height: 100%;
        width: 100%;

        .modal-body {
          padding: 0px !important;
          width: 100%;

          .content-body {
            width: 100%;
            padding: 30px 50px 30px 50px;
            box-sizing: border-box;
          }
        }
      }

    }
  }

  &.locationDeleteModal {
    box-sizing: border-box;

    .modal-dialog {
      box-sizing: border-box;
      height: 307px;
      width: 743px;
      padding: 0px !important;
      margin: 10.75rem auto !important;

      .modal-content {
        height: 100%;
        width: 100%;

        .modal-body {
          padding: 0px !important;
          width: 100%;

          .content-body {
            width: 100%;
            padding: 30px 50px 30px 50px;
            box-sizing: border-box;
          }
        }
      }

    }
  }

  &.login-block {
    .modal-dialog {
      width: 450px;
    }
  }

  &.session-modal {
    .modal-dialog {
      top: 100px
    }
  }

  .header-image {
    height: 100%;
    width: 100%;
    opacity: 0.5;
    object-fit: cover;
  }

  &.resetEmailModal {
    box-sizing: border-box;

    .modal-dialog {
      box-sizing: border-box;
      min-height: 500px;
      max-height: 550px;
      width: 600px;
      padding: 0px !important;
      margin: 10.75rem auto !important;

      .modal-content {
        height: 100%;
        width: 100%;

        .modal-body {
          padding: 0px !important;
          width: 100%;

          .content-body {
            width: 100%;
            padding: 30px 50px 30px 50px;
            box-sizing: border-box;
          }
        }
      }

    }
  }

  &.editProfilelModal {
    box-sizing: border-box;

    .modal-dialog {
      box-sizing: border-box;
      min-height: 500px;
      max-height: 550px;
      width: 620px;
      padding: 0px !important;
      margin: 10.75rem auto !important;

      .modal-content {
        height: 100%;
        width: 100%;

        .modal-body {
          padding: 0px !important;
          width: 100%;

          .content-body {
            width: 100%;
            padding: 30px 50px 30px 50px;
            box-sizing: border-box;
          }
        }
      }

    }
  }

  &.inviteUserModalCss {
    box-sizing: border-box;

    .modal-dialog {
      box-sizing: border-box;
      width: 574px;
      padding: 0px !important;
      margin-top:108px;

      .modal-content {
        height: 100%;
        width: 100%;

        .modal-body {
          padding: 0px !important;
          width: 100%;

          .content-body {
            width: 100%;
            padding: 30px 50px 30px 50px;
            box-sizing: border-box;
          }
        }
      }

    }
  }


  &.active-confirmation-popup {
    box-sizing: border-box;
    .modal-dialog {
      box-sizing: border-box;
      width: 523px;
      padding: 0px !important;
      margin-top:108px;
      .modal-content {
        height: 100%;
        width: 100%;
        .modal-body {
          padding: 0px !important;
          width: 100%;
          .content-body {
            width: 100%;
            padding: 30px 50px 30px 50px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

.pac-container {
  z-index: 10000 !important;
  display: block !important;

  &.hide {
    display: none !important;
  }
}

.pac-container:empty {
  display: none !important;
}

.pac-container:hide {
  display: none !important;
}

.md-drppicker {
  table {
    background-color: #F6F6F6 !important;
  }

  box-shadow: unset !important;

  td {
    &.today {
      border:2px solid #C4C5CA;
    }

    &.start {
      background-color: #8D0203 !important;
      border-radius: 100px;
      border-radius: 20px !important;
    }

    &.in-range {
      background-color: #2597D5 !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      color:white !important;
    }

    &.off {
      border-radius: 0px !important;
    }

    &.active {
      background-color: #2597D5 !important;
    }
  }

  th.month{
    position: relative;
    .dropdowns{
      position: absolute;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      top:-1.2px;
      background-color: #EAEAEA;
      border-radius: 4px;
      font-family: "Helvetica Neue";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      background-image:  url(/../assets/img/dropdown-cal.png);
      
      select{
        height: 42px;
        width:60px;
        border-radius: 4px;
        font-family: "Helvetica Neue";
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        
      }
    }
    .dropdowns:first-child{
      width:60px;
      left:-51px;
    }
    .dropdowns:nth-child(2){
      width:65px;
      right:-33px;
    }

  }
  th.available{
    &.prev{
      visibility: hidden;
    }
    &.next {
      visibility: hidden;
    }

  }

  th {
    // .available {
    //   &.prev {
    //    visibility: hidden !important;
    //   }

    //   &.next {
    //     visibility: hidden !important;
    //   }
    // }

    &.month {
      order-radius: 0px !important;
      color: #333333;
      font-family: "Helvetica Neue";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
    }
  }

  thead tr:first-child{
    height: 40px;
    background-color: #ECECEC;
  }

  thead tr:nth-child(2){ //styling of week days
    th{
      span{
        color: #333333;
        font-family: "Helvetica Neue";
      }
    }
  }
  thead tr{
    th.prev{
      height: 40px;
      width: auto;
    }
    th.next{
      height: 40px;
      width: auto;
    }
  }


  .calendar {
    max-width: 600px !important;

    &.left {
      .calendar-table {
        padding-right: 18% !important;
        width: 360px;
      }
    }

    &.right {
      .calendar-table {
        width: 360px;
      }
    }
  }
}

.calendar-container-offercom{
  .md-drppicker{
      .calendar .calendar-table{
      width: 372px !important;
    }
  }
}

.tabs-container .receipt-identifier .sponsor-pin .toggle-container {
  .switch-medium {
    .switch-text {
      .off {
        padding-top: 4px !important;
      }
    }
  }
}

.highcharts-background {
  fill: transparent !important;
}

.highcharts-credits {
  display: none;
}

.highcharts-title {
  display: none;
}

.transactions-loader {
  background: #ffffff;
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  opacity: 0.5;
  display: block;
  height: 100%;
  position: absolute;
  height: 1040px;
  z-index: 1;
}

.downloadloader-div {
  position: absolute;
  width: 350px;
  margin: 0px auto;
  top: 450px;
  background: transparent;
  padding: 20px;
  z-index: 1;
  border-radius: 10px;
  margin-left: 45%;

  img {
    display: inline;
    position: relative;
    top: 3px;
    padding-right: 10px;
  }

  span {
    display: inline;
    font-size: 14px;
    font-weight: 500;
    font-family: "Helvetica Neue";
  }
}

@media (min-width: 1441px) {
  .downloadloader-div {
    margin-left: 40%;
  }
}

.slick-dots li.slick-active button:before {
  color: #F00 !important;
}

.slick-dots li {
  width: 15px !important;
  height: 15px !important;
}

.slick-dots li button:before {
  font-size: 10px !important;
  color: #666666 !important;
  opacity: 1 !important;
}

button {
  border-width: 0px;
  padding: 0px;
}
.loader-div {
  position: relative;
  width: 250px;
  margin: 0px auto;
  top: 300px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;

  img {
    display: inline;
    padding-top: 10p;
    position: relative;
    top: 3px;
    padding-right: 10px;
  }

  span {
    display: inline;
    font-size: 14px;
    font-weight: 500;
    font-family: "Helvetica Neue";
  }
}