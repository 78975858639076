@import 'variables.less';

.clearfix {
  clear: both;
  font-size: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
  height: 0px;
}

.auto-margin {
  margin: 0px auto;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.max-width-1090 {
  max-width: 1090px;
}

.max-width-730 {
  max-width: 730px;
}

.width-320 {
  width: 320px;
}

.margin-bottom70 {
  margin-bottom: 70px;
}

.margin-bottom67 {
  margin-bottom: 67px;
}

.margin-top50 {
  margin-top: 50px !important;
}

.margin-bottom100 {
  margin-bottom: 100px;
}

.primary-button {
  background-color: @button-color;
  outline: none;
  border: none;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  color: @white-bg;
  height: 60px;
  display: block;
  cursor: pointer;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.defualt-button {
  border: 1px solid #333333;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  height: 60px;
  display: block;
  cursor: pointer;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.error {
  background-color: #F2E6E5;
  color: #8D0203 !important;
  font-family: "Helvetica Neue" !important;
  font-size: 10px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 9px;
  padding-left: 3%;
  border-bottom: 1px solid #8D0203;
  border-left: 1px solid #8D0203;
  border-right: 1px solid #8D0203;
  border-top: 0px !important;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;

  @media only screen and (min-device-width: 1281px) and (max-device-width: 1980px) {
    width: 100%;
  }

  width: 100%;
  padding-right: 3%;
  box-sizing: border-box;

  &.prefix {
    width: 74% !important;
    float: right;
  }
}

.global-error {
  background-color: #F2E6E5;
  color: #8D0203;
  font-size: 10px;
  font-weight: bold;
  border-bottom: 1px solid #8D0203;
  border-left: 1px solid #8D0203;
  border-right: 1px solid #8D0203;
  border-top: 1px solid #8D0203;
  border-radius: 2px;

  @media only screen and (min-device-width: 1281px) and (max-device-width: 1980px) {
    width: 77.1%
  }

  width: 77.1%;
  box-sizing: border-box;
  margin:0px auto;
  padding:20px;
  margin-top:40px;

  &.width100 {
    width: 100%;
  }
}

.select-error {
  background-color: #F2E6E5;
  color: #8D0203;
  font-size: 10px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 9px;
  padding-left: 3%;
  border-bottom: 1px solid #8D0203;
  border-left: 1px solid #8D0203;
  border-right: 1px solid #8D0203;
  border-top: 0px !important;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;

  @media only screen and (min-device-width: 1281px) and (max-device-width: 1980px) {
    width: 98%;
  }

  width: 98.5%;
  padding-right: 3%;
  box-sizing: border-box;
  margin-left:2%;
}

.is-invalid {
  border-top: 1px solid #8D0203 !important;
  border-left: 1px solid #8D0203 !important;
  border-right: 1px solid #8D0203 !important;
  border-bottom: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.firstcontent {
  width: 100%;
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 20px;

  &.no-bottom-border {
    border-bottom: 0px !important;
  }

  &.padding-bottom50 {
    padding-bottom: 50px;
  }

  &.padding-bottom0 {
    padding-bottom: 0px;
  }

  &.padding-top40 {
    padding-top: 40px;
  }

  p.description {
    height: 30px;
    color: #333333;
    font-family: Emirates;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    margin-top: 45px;
    margin-bottom: 19px;
    font-weight: 600;
  }

  span {
    height: 20px;
    width: 100%;
    max-width: 1100px;
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .contentdata {
    margin-bottom: 20px;

    span {
      height: 20px;
      width: 100%;
      color: #333333;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      display: inline-block;
    }
  }

  &.document-download {
    border-bottom: 0px;
    margin-top: 80px;

    .contentdata {
      width: 80%;
      float: left;
      min-height: 250px;
    }
  }

  .extrainfo {
    height: 20px;
    width: 100%;
    max-width: 1100px;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 35px;
    margin-bottom: 19px;
  }
}

.right-text-container {
  float: right;
  padding: 21px 30px;

  .sign-in {
    background-color: @button-color;
    border-radius: 4px;
    height: 40px;
    outline: none;
    border: none;
    color: @white-bg;
    font-size: 14px;
    font-weight: bold;
    line-height: 12px;
    text-align: center;
    padding: 0px 34px;
    font-family: 'Helvetica Neue';
    cursor: pointer;
    padding-top: 5px;
  }

  .become-partner {
    font-size: 14px;
    font-weight: bold;
    line-height: 12px;
    text-align: center;
    display: inline-block;
    color: @white-bg;
    margin-right: 40px;
    font-family: 'Helvetica Neue';
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

}

.button-container {
  margin-top: 5% !important;
  margin-bottom: 4%;
  align-items: center;
  margin-left: 20%;

  .backbutton {
    .defualt-button();
    width: 320px;
    .pull-left();

    &.opacity {
      opacity: 0.5;
      cursor: pointer;
    }
  }

  .registerbutton {
    .primary-button();
    width: 320px;
    .pull-left();
    margin-left: 6%;

    &.opacity {
      opacity: 0.5;
      cursor: default;
    }
  }

  &.opacity {
    opacity: 0.5;

    .backbutton,
    .registerbutton {
      cursor: default;
    }
  }
}

.formcontainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #D8D8D8;

  .infocontainer {
    width: 48%;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      width: 30%;
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
      width: 30%;
    }

    p {
      height: 30px;
      /* width: 160px; */
      color: #333333;
      font-family: Emirates;
      font-size: 25px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 40px;
      font-weight: 600;
    }

    span {
      height: 40px;
      /* width: 465px; */
      color: #333333;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

  }

  .boxcontainer {
    margin-top: 8%;
    width: 52%;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      width: 80%;
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
      width: 80%;
    }

    margin-bottom: 1.5%;

    .labelbox {
      margin-bottom: 20px;
      color: #A9A9A9;

      .gclabelbox {
        display: block;
        float: left;
        text-align: right;
        width: 43%;
        align-items: center;
        margin-right: 2%;
        margin-top: 17px;

        .gclabel {
          height: 17px;
          white-space: nowrap;
        }

        img {
          height: 12px;
          width: 12px;
          margin-left: 5px;
          position: relative;
          top: 1px;
        }
      }


      .input-group {
        width: 55%;
        float: left;
      }

      .gcinputbox {
        width: 54%;
        float: left;
        padding-left: 1%;
      }

      .gctext {
        height: 45px;
        width: 100%;
        border: 1px solid #A9A9A9;
        border-radius: 2px;
        background-color: #FFFFFF;
        box-shadow: inset 0 3px 2px 0 rgba(0, 0, 0, 0.06);
        padding-left: 10px;
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        box-sizing: border-box;

        &:focus {
          outline: none !important;
        }

        padding-top:5px;

        &.phone-number {
          .pull-right();
          width: 70% !important;
        }

        &.urlfield {
          width: 74%;
          border-left: none;
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
        }
      }

      .countrycode {
        height: 45px;
        width: 23%;
        border: 1px solid #A9A9A9;
        border-radius: 2px;
        background-color: #FFFFFF;
        box-shadow: inset 1px 3px 2px 0px rgba(0, 0, 0, 0.06);
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        .pull-left();
        text-align: center;
        padding-top: 6px;
        box-sizing: border-box;
        margin-left: 2%;
        box-sizing: border-box;
        padding-top: 18px;

        &:focus {
          outline: none !important;
        }

        .down-arrow {
          width: 9px;
          position: relative;
          top: -2px;
          left: 1px;
        }
      }

      .country-drop-down {
        height: 190px;
        width: 365px;
        background: url('../../assets/img/base_drop_down.png') no-repeat;
        position: relative;
        top: -18px;
        margin-bottom: -205px;
        z-index: 9;

        ul {
          padding-top: 8%;

          li {
            list-style-type: none;
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            clear: both;
            height: 27px;
            cursor: pointer;

            .country-name {
              float: left;
              width: 80%;
            }

            .country-code {
              float: left;
            }
          }
        }
      }

      .gcselect {
        height: 45px;
        width: 100%;
        border: 1px solid #A9A9A9;
        border-radius: 2px !important;
        border-radius: 0;
        appearance: none;
        background-position: right 50%;
        background-repeat: no-repeat;
        background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%20%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%20%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2214px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2014%2012%22%20enable-background%3D%22new%200%200%2014%2012%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20points%3D%223.862%2C7.931%200%2C4.069%207.725%2C4.069%20%22/%3E%3C/svg%3E);
        padding: .5em;
        padding-right: 1.5em;
        background-color: #FFFFFF;
        box-shadow: inset 0 3px 2px 0 rgba(0, 0, 0, 0.06);
        padding-left: 10px;
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        box-sizing: border-box;
        padding-top: 10px;

        &:focus {
          outline: none !important;
        }

        &.prefix {
          margin-top: 1px;
          width: 26%;
          border-right: none;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
      }
    }
  }

  &.opacity {
    opacity: 0.5;
    cursor: none;
  }
}

.documentcontent {
  width: 100%;
  border-bottom: 1px solid #D8D8D8;

  .documentblock {
    margin: 40px 0px 0px 0px;
    height: 887px;
    max-width: 1106px;
    width: 100%;
    background-color: #F6F6F6;
    position: relative;

    .documenttext {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 40% 37% 45% 37%;

      p {
        height: 58px;
        width: 296px;
        color: #333333;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        text-align: center;
      }


    }

  }
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.margin-bottom0 {
  margin-bottom: 0px;
}

.margin-top0 {
  margin-top: 0px !important;
}

.margin-bottom16 {
  margin-bottom: 16px;
}

.margin-top40 {
  margin-top: 40px;
}

.margin-top30 {
  margin-top: 30px;
}

.width-200 {
  width: 200px;
}

.margin-bottom20 {
  margin-bottom: 20px;
}

.margin-bottom50 {
  margin-bottom: 50px;
}

.margin-top36 {
  margin-top: 30px;
}

.width-240 {
  width: 240px;
}

.margin-top10 {
  margin-top: 10px !important;
}

.margin-top16 {
  margin-top: 16px;
}

.margin-left10 {
  margin-left: 10px;
}

.nopadding {
  padding: 0px !important;
}

.margin-bottom30 {
  margin-bottom: 30px;
}

.padding-bottom30 {
  padding-bottom: 30px;
}

.padding-bottom20 {
  padding-bottom: 20px;
}

.rewardcontainer {

  .leftcontainer,
  .rightcontainer {
    width: 50%;
    float: left;

    .boxcontainer {
      margin-top: 55px;
      width: 100%;

      p {
        height: 18px;
        /* width: 119px; */
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
      }

      .labelinput {
        margin-bottom: 10px;
        height: 18px;

        label {
          height: 17px;
          width: 44%;
          color: #A9A9A9;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          text-align: left;
          float: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span {
          height: 21px;
          width: 56%;
          color: #333333;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-lign: left;
          float: left;
          .text-overflow-hidden();
        }
      }
    }

    .emailid {
      padding-bottom: 10px !important;
      width: 100% !important;
    }
  }

  .rightcontainer {
    .boxcontainer {
      .labelinput {
        label {
          width: 45% !important;
        }

        span {
          width: 55% !important;
        }
      }
    }
  }

  &.border0 {
    border: 0px !important;
  }

  .width100 {
    width: 100% !important;
  }

  .doc-container {
    width: 29%;
    .pull-left();

    .document-title {
      width: 100%;
      .pull-left();
    }

    .doc-name,
    .doc-size {
      line-height: 30px;
      font-size: 14px;
      font-family: Helvetica Neue;
      height: 30px;
      .pull-left();
      .text-overflow-hidden();
      display: inline-block;
    }

    .doc-name {
      cursor: pointer;
      color: @title-color;
      max-width: 60%;
    }

    .doc-size {
      color: @doc-size-color;
      max-width: 25%;
      padding-left: 10px;
    }

    img {
      width: 132px;
      height: 162px;
      cursor: pointer;
    }
  }
}

.padding-bottom45 {
  padding-bottom: 45px;
}

.text-overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.documents-data {
  .margin-top30();
  height: 50px;
  .padding-bottom20();

  .primary-button {
    height: 50px;
    .width-200();
    .pull-left();
    .nopadding();

    &.buttonpadding {
      padding: 18px 0px 8px 0px !important;
    }

    input {
      width: 100%;
      position: relative;
      cursor: pointer;
      top: -36px;
      opacity: 0;
      height: 50px;
    }

    img {
      margin-left: 29px;
      margin-right: 7px;
      .pull-left();
    }

    .uploading {
      .pull-left();
      font-size: 12px;
    }

    &.is-disabled {
      background-color: @white-bg;
      border: 1px solid @title-color;
      color: @title-color;
      cursor: none;

      input {
        cursor: none;
      }
    }
  }

  .file-text {
    &.addpadding {
      .margin-top0();
      padding-bottom: 15px;
    }

    .pull-left();
    .margin-top16();
    margin-left: 48px;
    width: 21%;
    font-family: Helvetica Neue;
    font-size: 16px;
    color: @toggle-border;
    line-height: 18px;

    .doc-name,
    .doc-size {
      height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: Helvetica Neue;
      margin: 0px;
      font-size: 16px;
      line-height: 18px;
      .pull-left();
      padding-top: 2px;
      max-width: 50%;
    }

    .doc-size {
      .margin-left10();
      max-width: 25%;
      font-size: 14px;
      height: 20px;
      line-height: 20px;
    }

    img {
      .pull-left();
      .margin-left10();
      cursor: pointer;
    }

  }
}

.document-error {
  .margin-bottom30();
  padding: 6px;
  margin: 0px;
  border: 0px;
  height: 41px;
  border-radius: 2.2px;

  img {
    float: left;
    margin-top: 8px;
    margin-left: 8px;
  }

  .err-msg {
    font-size: 12px !important;
    margin-left: 7px;
    float: left;
  }
}

.documents-text,
.document-copy {
  height: 20px;
  width: 100%;
  color: @title-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.document-copy {
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  .margin-bottom0();
}

.margin-top60 {
  margin-top: 60px !important;
}

.toggle-container {
  .margin-top36();

  .switch-medium {
    width: 58px !important;
    height: 28px !important;
    border-radius: 50px !important;
    background-color: @white-bg  !important;
    border: 1px solid @toggle-border  !important;
    border-color: unset !important;

    &.checked {
      background: @toggle-checked-bg  !important;
      border: 1px solid @toggle-checked-bg  !important;
      border-color: unset !important;

      small {
        background-image: url('/assets/img/tic.png') !important;
        background-position: center !important;
        background-color: @white-bg  !important;
        background-repeat: no-repeat !important;
      }
    }

    small {
      height: 20px !important;
      width: 20px !important;
      border: 1px solid rgba(169, 169, 169, 0.5) !important;
      background: @toggle-button  !important;
      margin-left: 3px !important;
      margin-top: 3px !important;
    }

    .switch-text {
      span {
        height: 10px !important;
        font-size: 10px !important;
        font-weight: bold;
        position: relative !important;
        top: 5px;
      }

      .on {
        width: 20px;
        top: 18% !important;
        left: 16% !important;
        color: @white-bg  !important;
      }

      .off {
        width: 20px;
        right: 16% !important;
        top: 18% !important;
        color: @title-color  !important;
        position: absolute !important;
      }
    }
  }

  .toggle-text {
    height: 20px;
    margin-left: 14px;
    margin-top: 5px;
  }

  &.top32 {
    .switch-text {
      .off {
        top: 32% !important;
      }
    }
  }

}

.inner-container {
  width: 100%;
  min-height: 880px;
  overflow: auto;

  .left-container {
    float: left;
    width: 18.5%;
    background: #F5F8F9;
    height: 100%;
    padding-top: 50px;
    box-sizing: border-box;
  }

  .right-container {
    float: left;
    width: 81.5%;
    height: 100%;
    box-sizing: border-box;

    .inner-title-header {
      height: 144px;
      background: linear-gradient(rgba(246, 246, 246, .55),
        rgba(246, 246, 246, .55)),
        url('/assets/img/Base.png');
      padding-top: 8%;

      &.change-padding {
        padding-top: 4%;
      }

      box-sizing: border-box;

      &.inner-title-payment-header {
        padding-top: 3% !important;
      }

      .offer-btn {
        height: 50px;
        width: 233px;
        border-radius: 4px;
        background-color: #F00;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
        text-align: center;
        float: right;
        margin-right: 40px;
        border: 0px;
        cursor: pointer;

        &:focus {
          outline: none !important;
        }
      }

      .sub-title {
        float: left;
        color: #000000;
        font-family: Emirates;
        font-size: 28px;
        font-weight: 500;
        line-height: 30px;
        padding-left: 3%;
        font-weight: 600;

        &.top-padding {
          padding-top: 10px;
        }
        &.white-color{
          color:white;
        }
      }

      .refund-btn {
        height: 38px;
        width: 107px;
        border: 1px solid #F6F6F6;
        border-radius: 28px;
        background-color: #2597D5;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
        color: #FFFFFF;
        font-family: "Helvetica Neue";
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: center;
        box-sizing: border-box;
        padding-top: 5px;
        float: left;
        display: block;
        cursor: pointer;
        margin-left: 15px;

        &:focus {
          outline: none;
        }
      }

      .cancel-btn {
        height: 38px;
        width: 107px;
        border: 1px solid #F6F6F6;
        border-radius: 28px;
        background-color: #333333;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
        color: #FFFFFF;
        font-family: "Helvetica Neue";
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: center;
        box-sizing: border-box;
        padding-top: 5px;
        float: left;
        display: block;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      .float-left {
        float: right;
        margin-right: 40px;
      }

      .search-container {
        height: 34px;
        width: 260px;
        border: 1px solid #A9A9A9;
        border-radius: 19px;
        background-color: #FFFFFF;
        box-shadow: inset 0 3px 2px 0 rgba(0, 0, 0, 0.06);
        display: block;
        float: left;
        padding-top: 3px;
        box-sizing: border-box;
        padding-left: 15px;

        &::placeholder {
          color: #A9A9A9;
          font-family: "Helvetica Neue";
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          padding-left: 10px;
        }

        &.opacity {
          opacity: 0.3;
        }

        &:focus {
          outline: none;
        }
      }

      .down-arrow {
        float: left;
        width: 10px;
        height: 10px;
        position: relative;
        top: 15px;
        left: -26px;
        cursor: pointer;
      }

      a.download-link {
        height: 41px;
        width: 41px;
        background: url('../../assets/img/download_bg.png') no-repeat;
        padding: 12px 0px 0px 13px;
        box-sizing: border-box;
        display: block;
        float: right;
        margin-right: 15px;
        text-decoration: none;

        &.opacity {
          opacity: 0.3;
        }

      }

      [hidden] {
        display: none !important;
      }

      ul.download-menu {
        height: 77pxpx;
        width: 142px;
        border: 1px solid #A9A9A9;
        border-radius: 2px;
        background-color: #FFFFFF;
        position: relative;
        list-style: none;
        padding: 0px;
        left: -61px;
        top: 25%;
        z-index: 1;
        margin: 0px;
        margin-bottom: -110px;

        &:before {
          content: '';
          height: 0;
          left: 57px;
          position: absolute;
          top: -10px;
          width: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #fff;
          z-index: 2;
        }

        li {
          color: #333333;
          font-size: 14px;
          font-weight: 500;
          line-height: 25px;
          height: 25px;
          padding-top: 8px;
          text-align: center;
          cursor: pointer;
        }
      }



      .download-container {
        float: left;
        padding: 0px 0% 0px 4%;

        @media only screen and (max-width: 1300px) {
          padding: 0px 0% 0px 1% !important;
        }

        a {
          display: inline-block;
          color: #000000;
          font-family: Emirates;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
          margin-right: 58px;
          text-decoration: none;
          padding-top: 8px;
          font-weight: 600;

      
  

          @media only screen and (max-width: 1400px) {
            margin-right: 40px !important;
          }

          @media only screen and (max-width: 1230px) {
            margin-right: 30px !important;
          }

          @media only screen and (max-width: 1170px) {
            margin-right: 20px !important;
          }

          @media only screen and (max-width: 1100px) {
            margin-right: 10px !important;
          }

          @media only screen and (max-width: 1040px) {
            margin-right: 5px !important;
          }


          &.selected {
            font-size: 22px;
            font-weight: bold;
            line-height: 20px;
            text-align: center;
            border-bottom: 2px solid #F00;
            padding-bottom: 19px;
          }
        }

        [hidden] {
          visibility: hidden !important;
        }

        a.download-link {
          height: 41px;
          width: 41px;
          background: url('../../assets/img/download_bg.png') no-repeat;
          padding: 11px 0px 0px 2px;
          box-sizing: border-box;
        }

        ul.download-menu {
          height: 80px;
          width: 142px;
          border: 1px solid #A9A9A9;
          border-radius: 2px;
          background-color: #FFFFFF;
          position: relative;
          list-style: none;
          padding: 0px;
          left: 412px;
          top: 0px;
          z-index: 1;
          margin: 0px;
          margin-bottom: -110px;

          &:before {
            content: '';
            height: 0;
            left: 57px;
            position: absolute;
            top: -10px;
            width: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #fff;
            z-index: 2;
          }

          li {
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            line-height: 25px;
            height: 25px;
            padding-top: 8px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }

    .inner-header-layer {
      height: 146px;
      background-color: rgba(246, 246, 246, 0.75);
      position: relative;
      top: -146px;
      margin-bottom: -146px;
    }
  }
}

.pagination {
  margin-top: 10px;
  width: 120px;
  float: right;
  margin-right: 5%;

  .link {
    color: #4A4A4A;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: block;
    text-decoration: none;
    float: left;
    border: 0px;
    font-weight: normal;
    cursor: pointer;
    padding: 0px;
    outline: none;
    background: none;

    &.next-link {
      float: right !important;
    }

    &.opacity {
      opacity: .5;
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
    }
  }
}


.padding-top25 {
  padding-top: 25px;
}

.margin-bottom10 {
  margin-bottom: 10px;
}

.training-materials {
  .document-row {
    margin-bottom: 10px;

    label {
      color: #333333;
      font-family: "Helvetica Neue";
      font-size: 16px;
      font-weight: bold;
      line-height: 18px;
      display: block;
      float: left;
      padding-top: 8px;
    }

    .circle {
      height: 28px;
      width: 28px;
      background-color: #666666;
      border-radius: 50%;
      float: right;
      cursor: pointer;

      &.right-margin {
        margin-right: 6%;
      }

      img {
        padding-top: 6px;
        padding-left: 6px;

        &.email-image {
          padding-top: 8px;
        }
      }
    }
  }

  .material-one {
    width: 40%;
    float: left;
  }

  .material-two {
    width: 45%;
    float: left;
    margin-left: 12%;
  }
}

.margin0 {
  margin: 0px !important;
}

.brand-book-links {
  label {
    color: #333333;
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    display: inline-block;

    &.spacer {
      padding-left: 20%;
    }
  }

  .link {
    height: 20px;
    width: 101px;
    color: #2597D5;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: inline-block;
    padding-left: 10%;
  }
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #D8D8D8;
  border: 0px;
  margin-top: 50px;
}


.padding-top40 {
  padding-top: 40px;
}

.no-border {
  border: 0px;
}

.nodata {
  //background-image: url('../../assets/img/message_background@2x.png');
  //background-repeat: no-repeat;
  height: 880px;
  width: 100%;

  .sub-title {
    float: left;
    color: #000000;
    font-family: Emirates;
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
    padding-left: 2%;
    padding-top: 5%;
    font-weight: 600;
  }

  .offer-btn {
    height: 50px;
    width: 233px;
    border-radius: 4px;
    background-color: #F00;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    float: right;
    margin-right: 40px;
    border: 0px;
    margin-top: 5%;

    &:focus {
      outline: none !important;
    }
  }

  .no-data-container {
    width: 482px;
    margin: 0px auto;
    padding-top: 25%;

    &.no-data-container-offers {
      padding-top: 22%;
    }

    .no-data-image {
      background-image: url('../../assets/img/hero_icon_background_small.png');
      height: 143.4px;
      width: 143.4px;
      margin: 0px auto;

      img {
        margin: 0px auto;
        display: block;
        padding-top: 31%;
      }
    }

    .no-data-image-0ffers {
      background-image: url('../../assets/img/hero_icon_background_big.png');
      background-repeat: no-repeat;
      height: 235px;
      width: 235px;
      margin: 0px auto;

      img {
        margin: 0px auto;
        display: block;
        padding-top: 26%;
      }
    }

    .no-data-available {
      color: #222631;
      font-size: 30px;
      font-weight: bold;
      line-height: 36px;
      text-align: center;
      text-transform: uppercase;
      margin-top: 20px;
    }

    .no-data-description {
      height: 35px;
      width: 482px;
      color: #333333;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: center;
      margin-top: 30px;
    }
  }
}

.table-header {
  background: #ffffff;
  box-sizing: border-box;
}

.table-header-th {
  color: #A9A9A9;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  width: 11%;
  text-align: left;
  padding-top: 32px;
  padding-bottom: 14px;
  padding-right: 10px;
}

.table-body {
  padding-left: 40px;
  height: 50px;
  box-sizing: border-box;
}

.table-body-td {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  width: 11%;
  text-align: left;
  padding-top: 6px;
  padding-right: 10px;
}

.selectfield-style {
  -webkit-appearance: menulist;
  box-sizing: border-box;
  align-items: center;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  white-space: nowrap;
  -webkit-rtl-ordering: logical;
  outline: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  background: url('../../assets/img/drop_down_arrows_pressed.png') no-repeat;
  -webkit-appearance: none;
  appearance: none;
  background-position: 93% center;
  padding-left: 10px;
  padding-top: 5px;

  &:focus {
    outline: none;
  }
}

.padding-bottom50 {
  padding-bottom: 50px;
}

.padding-top41 {
  padding-top: 41px;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

.tooltip {
  text-align: center;
  padding: 0 50px;
}

.tooltip [tooltip] {
  display: inline-block;
  margin: 50px 20px;
  width: 180px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
}

.ng-tooltip {
  position: absolute;
  max-width: 200px;
  font-size: 14px;
  text-align: center;
  padding: 10px 10px;
  background: #ffffff;
  border-radius: 4px;
  z-index: 1000;
  color: #333333;
  font-family: "Helvetica Neue";
  font-weight: 500;
  line-height: 20px;
  opacity: 0;
  border: 1px solid #A9A9A9;
}
.large-ng-tooltip{
  max-width: 294px !important;
  padding: 13px 26px;
  color: #333333;
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25.2px;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0 1px 4px 1px #D8D8D8;
  margin-top:2px;
}


.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent;
}

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black;
}

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}

.inner-header-container {
  height: 144px;
  position: relative;

  .header-image {
    height: 100%;
    width: 100%;
    opacity: 0.5;
    object-fit: cover;
  }
}

.noheaderbg {
  position: absolute;
  top: 0%;
  width: 100%;
  background: none !important;
}

.emailslist {
  width: 54%;
  float: left;
  padding-left: 1%;
  margin-top: 10px;
  max-height: 240px;
  overflow-y: auto;

  &.review {
    margin-top: 0px;
  }

  .email {
    width: 100%;
    float: left;
    padding-bottom: 10px;

    span {
      color: #333333;
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 0px;
      width: 88%;
      float: left;
    }

    img {
      float: left;
      cursor: pointer;
    }
  }
}
