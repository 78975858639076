@font-face {
    font-family: Helvetica Neue;
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue.eot');
    src: url('/assets/fonts/helvetica-neue/HelveticaNeueLTStd-Roman.otf')
    format('truetype');

}
@font-face {
    font-family: Helvetica Neue Light;
  //  src: url('/assets/fonts/helvetica-neue/HelveticaNeue-UltraLight.eot');
   src: url('/assets/fonts/helvetica-neue/HelveticaNeueLTStd-ThEx.otf')
    format('truetype');

}
@font-face {
    font-family: Helvetica Neue Bold;
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-Bold.eot');
    src:url('/assets/fonts/helvetica-neue/HelveticaNeueLTStd-Bd.otf')
    format('truetype');
}


@font-face {
    font-family: Emirates;
    src: url('/assets/fonts/emirates-font/Emirates-Light.eot');
    src: url('/assets/fonts/emirates-font/Emirates-Light.otf');
}

@font-face {
    font-family: Gotham Rounded;
    src: url('/assets/fonts/gotham-font/Gotham-Rounded-Medium.eot');
    src: url('/assets/fonts/gotham-font/Gotham-Medium.otf');
}

