.tooltip {
  width: 356px !important;
  color: #333333 !important;
  font-family: "Helvetica Neue" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 25px !important;
  border: 1px solid #A9A9A9 !important;
  border-radius: 2px !important;
  background: #FFFFFF !important;
  box-shadow: inset 0 3px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 4px 1px #D8D8D8 !important;
}